.row {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    column-gap: 1rem;
    padding: 0 1rem;
    margin: 1rem 0;
}

.row-time {
    display: flex;
    flex-direction: column;
    background: white;
    box-shadow: 0 2px 5px 0 rgba(164, 173, 186, 0.25);
    margin-top: 1rem;
    padding: 0 1rem;
}

.row-time h1 {
    margin-bottom: 0;
}



