:root {
  --primary-color: #19253b;
  --primary-color-hover: #262f3f;
  --secondary-color: #19253b;
  --secondary-color-hover: #19253b;
  --danger-color: #dc3545;
  --danger-color-hover: #c82333;
  --ramtek-color: #03a9f4;
}

html {
	height: 100%;
	background-color: #f7f7f8;
}


body {
	margin: 0;
	padding: 0;
	height: 100% !important;
	background-color: #f7f7f8 !important;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

hr {
    border: solid 0.01rem #f0f0f0;
}
