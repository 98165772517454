.voucher-container {
    width: 100%;
    padding: 1rem;
}

.voucher-item {
    background: white;
    padding: 1rem;
    margin: 1rem 0
}
