.feedback-form-container {
    display: flex;
    flex-direction: column;
    padding: 2rem;
    background: white;
}

.feedback-form {
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 100%;
}
