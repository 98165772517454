html {
    background: #f7f7f8;
}

.main-content {
    text-align: center;
	background: blue;
	height: 100%;
	min-height: 100%;
	display: flex;
}
