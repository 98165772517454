.admin-item {
    display: inline-flex;
    align-self: stretch;
    flex-direction: column;
    border: 0.25rem solid light-grey;
    padding: 1rem;
    background: white;
    width: 100%;
    height: 100%;
    border-radius: 1rem;
}
