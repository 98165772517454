.dashboard-page {
    display: flex;
    flex-direction: column;
    padding: 2rem;
}

.dashboard-header {
    display: flex;
    flex-direction: row;
    background: white;
    padding: 1rem;
    width: 100%;
}

.dashboard-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    column-gap: 1rem;
    row-gap: 1rem;
    padding: 2rem;
}

.dashboard-card {
    display: flex;
    flex-direction: column;
    background: white;
    padding: 1rem;
    align-items: center;
}

