.rounded-item {
    display: inline-flex;
    align-self: stretch;
    flex-direction: column;
    padding: 1rem;
    background: white;
    overflow: scroll;
    border-radius: 1rem;
    width: 100%;
    height: 100%;
}


