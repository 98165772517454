.staff-bookings-container {
    display: flex;
    flex-direction: row;
    background: white;
}


.staff-bookings-header {
    display: flex;
    flex-direction: row;
    background: white;
    padding: 0.5rem;
    border-bottom: 0.1rem #dddddd solid;
    align-items: center;
}

.staff-bookings-buttons {
    display: flex;
    flex-direction: column;
    border-right: 0.1rem #dddddd solid;
    overflow: scroll;
    padding: 1rem;
}

.staff-bookings-grid {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 1rem;
}

