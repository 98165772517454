.datepicker {
    z-index: 9999 !important;
}

div.react-datepicker-popper {
  z-index: 1000;
}

.react-datepicker-popper {
    z-index: 9999 !important;
}

.red-highlight {
	color: blue;
}

.react-datepicker {
    box-shadow: 0 2px 5px 0 rgba(164, 173, 186, 0.25);
	border: none;
}

.react-datepicker__day--selected{
	background: var(--primary);
}
