.user-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    background: white;
}

.user-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 0.1rem #dddddd solid;
    padding: 0.5rem 1rem;
}

.user-form {
    display: flex;
    flex-direction: column;
    width: 100%;
    background: white;
    padding: 2rem;
}

.user-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    column-gap: 1rem;
    row-gap: 1rem;
    padding: 1rem;
}



