.login-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.login-box {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 600px;
    padding: 2rem;
    box-shadow: 0 2px 5px 0 rgba(164, 173, 186, 0.25);
    background: #ffffff;
    margin-top: 2rem;
}

.logo {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 4rem;
}
